import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";

type createRollsetProps = {
	UpperRollId: number | null;
	LowerRollId: number | null;
	SectionId: number | null;
	StandId: number | null;
}

export const createRollset = async ({UpperRollId, LowerRollId, SectionId, StandId}: createRollsetProps) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "UpperRollId", value: UpperRollId },
		{ name: "LowerRollId", value: LowerRollId },
		{ name: "SectionId", value: SectionId },
		{ name: "StandId", value: StandId },
	]

	const resp = await apiService.callV2("[M2].[CreateRollset]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error creating rollset");
	}
};


import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SectionFamilySelector } from "../../../../../shared/selectors";
import {
  CATALOG_SECTION_INITIAL_VALUES,
  CatalogSection,
} from "../../../models/CatalogSection";
import {
  deleteSection,
  upsertSection,
} from "../../../repositories/CatalogSectionRepository";
import { useSections } from "../CatalogSectionPage";

type Props = {
  SectionId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ SectionId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const succesfullyMessage = `The section was ${modal}d succesfully`;

  const { data: rows, isLoading } = useSections(
    SectionId || undefined,
    (error) => setError(error.message)
  );

  const section = rows ? rows[0] : null;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogSection>({
    defaultValues: CATALOG_SECTION_INITIAL_VALUES,
  });

  const checkChanges = useCallback(
    (data: CatalogSection) => {
      const hasChanged =
        data.SectionName !== section?.SectionName ||
        data.SectionFamilyId !== section?.SectionFamilyId;
      setHasChanges(hasChanged);
    },
    [section]
  );

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteSection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertSection, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
      setHasChanges(false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<CatalogSection> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      deleteMutation.mutate(data.SectionId);
    } else {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        if (section) {
          setValue("SectionId", section?.SectionId);
          setValue("SectionName", section?.SectionName);
          setValue("SectionFamilyId", section?.SectionFamilyId);
        }
    }
  }, [modal, section]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as CatalogSection);
  }, [formValues]);

  return (
    <>
      <MesfModal
        title={`${title} SECTION`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="section-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="SectionName"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={onChange}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="SectionFamilyId"
                      control={control}
                      render={({ field }) => (
                        <SectionFamilySelector
                          label="Section Family"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              disabled={!hasChanges && modal !== "delete"}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Section"
      />
    </>
  );
};

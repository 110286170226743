import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SectionFamily } from "../models/SectionFamily";
import { getSectionFamilies } from "../repositories/SectionFamilyRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

const useSectionFamilies = (
  sectionFamilyId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<SectionFamily[], Error>({
    queryKey: ["sectionFamilies", sectionFamilyId],
    queryFn: () => getSectionFamilies(sectionFamilyId),
    onError: onError,
  });
};

export const SectionFamilySelector = ({
  label,
  value,
  onChange,
  disabled,
}: Props) => {
  const [error, setError] = useState("");

  const { data: sectionFamilies, isLoading } = useSectionFamilies(
    undefined,
    (error) => setError(error.message)
  );

  const selectedValue = sectionFamilies?.find(
    (section) => section.SectionFamilyId === value
  ) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={sectionFamilies || []}
        getOptionLabel={(option) => option.SectionFamilyName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.SectionFamilyId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading sections"
      />
    </>
  );
};

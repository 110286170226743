import { ErrorModal, getMomentTz, MesfModal } from "@dexteel/mesf-core";
import { FormControl, FormHelperText, Grid } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Chock, CHOCK_INITIAL_VALUES } from "../../../../models/parts/Chock";
import {
  deleteChock,
  upsertChock,
} from "../../../../repositories/parts/ChockRepository";
import { PartFields } from "../../PartFields";
import { useChocks } from "../ChockPage";

type Props = {
  ChockId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};

const moment = getMomentTz();

export const UpsertDeleteModal = ({ ChockId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const succesfullyMessage = `The chock was ${modal}d succesfully`;

  const { data: rows, isLoading } = useChocks(ChockId || undefined, (error) =>
    setError(error.message)
  );

  const chock = rows ? rows[0] : null;

  const { control, setValue, reset, handleSubmit } = useForm<Chock>({
    defaultValues: CHOCK_INITIAL_VALUES,
    mode: "onChange",
  });

  const checkChanges = useCallback(
    (data: Chock) => {
      const hasChanged =
        data.LocationId !== chock?.LocationId ||
        data.PartName !== chock?.PartName ||
        data.RebuildTimestamp !== chock?.RebuildTimestamp ||
        data.BearingChangeTimestamp !== chock?.BearingChangeTimestamp;
      setHasChanges(hasChanged);
    },
    [chock]
  );

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["chocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["chocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
      setHasChanges(false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Chock> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      deleteMutation.mutate(data.PartId);
    } else {
      upsertMutation.mutate(data);
    }
  };

  const validateNotInFuture = (description: string, value: Date | null) => {
    if (value && value > new Date()) {
      return `${description} time cannot be in the future.`;
    }
    return true;
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        if (chock) {
          setValue("PartId", chock?.PartId);
          setValue("PartName", chock?.PartName);
          setValue("RFIDTag", chock?.RFIDTag);
          setValue("LocationId", chock?.LocationId);
          setValue("RebuildTimestamp", chock?.RebuildTimestamp);
          setValue("BearingChangeTimestamp", chock?.BearingChangeTimestamp);
        }
    }
  }, [modal, chock]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Chock);
  }, [formValues]);

  return (
    <>
      <MesfModal
        title={`${title} CHOCK`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="chock-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<Chock>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RebuildTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Rebuild", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Rebuild Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={field.value ? moment(field.value) : null}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="BearingChangeTimestamp"
                      control={control}
                      shouldUnregister
                      rules={{
                        validate: {
                          notInFuture: (value) =>
                            validateNotInFuture("Bearing Change", value),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <DatePicker
                            disabled={modal === "delete"}
                            label="Bearing Change Date"
                            format="MM/DD/YYYY"
                            views={["year", "month", "day"]}
                            value={field.value ? moment(field.value) : null}
                            onChange={field.onChange}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!error,
                              },
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              disabled={!hasChanges && modal !== "delete"}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Chock"
      />
    </>
  );
};

import { MenuOptionType } from "@dexteel/mesf-core";
import { Rollset } from "../../models/Rollset";

type Props = {
  setSelectedRowData: (rollset: Rollset) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useRollsetOptionsFunctions = ({
  setSelectedRowData,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: Rollset) => {
    let options: MenuOptionType[] = [];
    return options;
  };
  return {
    getMenuOptions,
  };
};

import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { Stand } from "../../../../models/Stand";
import { Arbor, ARBOR_INITIAL_VALUES } from "../../../../models/parts/Arbor";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { upsertArbor } from "../../../../repositories/parts/ArborRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

const useStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  arbor: Arbor | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const ArborDetails = ({ arbor, modal, onHide }: Props) => {
  const classes = useStyles();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The arbor was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit, getValues } = useForm<Arbor>({
    defaultValues: ARBOR_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: arbor?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });

  const checkChanges = useCallback(
    (data: Arbor) => {
      const stansMappeds = standsSelected.map((stand: Stand) => ({
        ...stand,
        PartId: arbor?.PartId,
        PartName: arbor?.PartName,
      }));

      const hasChanged =
        data.PartName !== arbor?.PartName ||
        data.LocationId !== arbor?.LocationId ||
        data.Comments !== arbor?.Comments ||
        JSON.stringify(stansMappeds) !== JSON.stringify(standsOfPart);

      setHasChanges(hasChanged);
    },
    [arbor, standsSelected]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertArbor, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["arbors"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);

      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Arbor> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset();
      setIsSubmitLoading(false);
      if (arbor) {
        setValue("PartId", arbor?.PartId);
        setValue("PartName", arbor?.PartName);
        setValue("RFIDTag", arbor?.RFIDTag);
        setValue("LocationId", arbor?.LocationId);
        setValue("Comments", arbor?.Comments);
      } else {
        setStandsSelected([]);
      }
    }
    setHasChanges(false);
  }, [modal, arbor]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Arbor);
  }, [formValues, standsSelected]);

  return (
    <>
      {arbor === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select an arbor or create a new one</Typography>
        </div>
      ) : (
        <form id="upsert-arbor-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Arbor>
              control={control}
              disabled={modal === "delete"}
            />
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid item md={12} sm={12} style={{ marginBottom: 10 }}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      label="Comments"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={field?.value}
                      maxRows={3}
                      minRows={3}
                      multiline
                      autoComplete="off"
                      disabled={modal === "delete"}
                      onChange={(event) => {
                        if (event.target.value.length <= 200) {
                          field.onChange(event.target.value);
                        }
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                    {(getValues("Comments")?.length as number) !== 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        {`${getValues("Comments")?.length ?? 0}/200 max.`}
                      </span>
                    )}
                    {(getValues("Comments")?.length as number) == 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        Max. 200
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: "0px 15px" }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogSection } from "../models/CatalogSection";
import { get } from "lodash-es";

export const getSections = async (sectionId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (sectionId)
		parameters.push({ name: "SectionId", value: sectionId });

	const resp = await apiService.callV2("[M2].[GetSections]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogSection[];
	}
	else {
		throw new Error(resp.message || "Error fetching sections");
	}
};
export const upsertSection = async (section: CatalogSection) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: section.SectionId },
		{ name: "SectionName", value: section.SectionName },
		{ name: "SectionFamilyId", value: section.SectionFamilyId }
	];

	const resp = await apiService.callV2("[M2].[UpsertSection]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting section");
	}
};

export const deleteSection = async (sectionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: sectionId }
	];

	const resp = await apiService.callV2("[M2].[DeleteSection]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting section");
	}
};
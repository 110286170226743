import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { TableCarrierAssembly } from "./components/TableCarrierAssembly";

export const CarrierPage = () => {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1}>
            <TableCarrierAssembly />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { EdgerRoll } from "../../../../configuration/models/parts/EdgerRoll";
import { optionsStatusCodes } from "../../../../configuration/pages/parts/edger-rolls/components/EdgerRollDetails";
import { Part } from "../../models/Part";
import { getEdgerRolls } from "../../repositories/parts/EdgerRollRepository";

type useRollsProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: EdgerRoll[]) => void;
};

export const useRolls = ({ partId, onError, onSuccess }: useRollsProps) => {
  return useQuery<EdgerRoll[], Error>({
    queryKey: ["edger-rolls", partId],
    queryFn: () => getEdgerRolls(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  part: Part | null;
};

export const SpecificEdgerRollData = ({ part }: Props) => {
  const [error, setError] = useState("");
  const [roll, setRoll] = useState<EdgerRoll>();

  const { data: rolls } = useRolls({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: EdgerRoll[]) => {
      setRoll(data[0]);
    },
  });

  return (
    <>
      <Grid container>
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: 10,
            padding: 10,
            margin: "0 0 10px",
            width: "100%",
          }}
        >
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value="Edger"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={roll?.NewDiameter || ""}
                label="New Diameter"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={roll?.Diameter || ""}
                label="Diameter"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={roll?.ScrapDiameter || ""}
                label="Scrap Diameter"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={
                  optionsStatusCodes.find(
                    (status) => status.Code === roll?.RollStatusCode
                  )?.Name || ""
                }
                label="Status"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={roll?.BarrelName || ""}
                label="Barrel"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </div>
      </Grid>

      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

export interface UniversalRoll {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null,
	RollStatusCode: string | null;
	RollTypeCode: string | null;
	DonutDiameter: number | null;
	DonutNewDiameter: number | null;
	DonutScrapDiameter: number | null;
	DonutPartId: number | null;
	DonutPartName: string | null;
	BarrelId: number | null;
	ArborPartId: number | null;
	ArborPartName: string | null;
	PercentUsage: number | null;
	CampaignTons: number | null;
	TotalTons: number | null;
	IsActive: boolean | null;
}

export const UNIVERSAL_ROLL_INITIAL_VALUES: UniversalRoll = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	RollStatusCode: 'R',
	RollTypeCode: 'E',
	DonutDiameter: null,
	DonutNewDiameter: null,
	DonutScrapDiameter: null,
	DonutPartId: null,
	DonutPartName: '',
	BarrelId: null,
	ArborPartId: null,
	ArborPartName: "",
	PercentUsage: null,
	CampaignTons: null,
	TotalTons: null,
	IsActive: true
};
import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Typography } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { CassetteChockSelector, PassShapeSelector } from "../../../../../../shared/selectors";
import {
  Cassette,
  CASSETTE_INITIAL_VALUES,
} from "../../../../models/parts/Cassette";
import { Stand } from "../../../../models/Stand";
import { upsertCassette } from "../../../../repositories/parts/CassetteRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  cassette: Cassette | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const CassetteDetails = ({ cassette, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The cassette was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit, watch } = useForm<Cassette>({
    defaultValues: CASSETTE_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: cassette?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });

  const checkChanges = useCallback(
    (data: Cassette) => {
      const standsMappeds = standsSelected.map((stand: Stand) => ({
        ...stand,
        PartId: cassette?.PartId,
        PartName: cassette?.PartName,
      }));

      const formChanged =
        data.LocationId !== cassette?.LocationId ||
        data.PartName !== cassette?.PartName ||
        data.AveCassetteChockId !== cassette?.AveCassetteChockId ||
        data.RivCassetteChockId !== cassette?.RivCassetteChockId ||
        JSON.stringify(standsMappeds) !== JSON.stringify(standsOfPart);

      setHasChanges(formChanged);
    },
    [cassette, standsSelected]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertCassette, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["cassettes"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);

      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Cassette> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset();
      setIsSubmitLoading(false);
      if (cassette) {
        setValue("PartId", cassette?.PartId);
        setValue("PartName", cassette?.PartName);
        setValue("RFIDTag", cassette?.RFIDTag);
        setValue("LocationId", cassette?.LocationId);
        setValue("PassShapeId", cassette?.PassShapeId);
        setValue("RivCassetteChockId", cassette?.RivCassetteChockId);
        setValue("AveCassetteChockId", cassette?.AveCassetteChockId);
      } else {
        setStandsSelected([]);
      }
    }
  }, [modal, cassette]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Cassette);
  }, [formValues, standsSelected]);

  const validateCassettes = (value: number | null, otherFieldName: any) => {
    const otherValue = watch(otherFieldName);
    return (
      value !== otherValue ||
      value === null ||
      `Cannot be the same as ${
        otherFieldName === "RivCassetteChockId" ? "River" : "Avenue"
      } Cassette`
    );
  };

  return (
    <>
      {cassette === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a cassette or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Cassette>
              control={control}
              disabled={modal === "delete"}
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="RivCassetteChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateCassettes(value, "AveCassetteChockId"),
                  },
                  deps: ["AveCassetteChockId"],
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CassetteChockSelector
                    label="River Cassette Chock"
                    disabled={modal === "delete"}
                    onChange={onChange}
                    fieldError={error}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="AveCassetteChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateCassettes(value, "RivCassetteChockId"),
                  },
                  deps: ["RivCassetteChockId"],
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CassetteChockSelector
                    label="Avenue Cassette Chock"
                    disabled={modal === "delete"}
                    onChange={onChange}
                    fieldError={error}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="PassShapeId"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <PassShapeSelector
                    label="Pass Shape"
                    onChange={onChange}
                    fieldError={error}
                    value={value}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <>
              <Grid item md={12}>
                <StandGrid
                  onSelectionChanged={setStandsSelected}
                  disabled={modal === "delete"}
                  standsOfPart={standsSelected}
                />
              </Grid>
            </>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

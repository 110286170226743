import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Guide } from "../../models/parts/Guide";

export const getGuides = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetGuides]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Guide[];
	}
	else {
		throw new Error(resp.message || "Error fetching guides");
	}
};
export const upsertGuide = async (guide: Guide) => {
	const apiService = new MESApiService();
	
	const parameters: spParameter[] = [
		{ name: "PartId", value: guide.PartId },
		{ name: "PartName", value: guide.PartName },
		{ name: "LocationId", value: guide.LocationId },
	];

	if (guide.GuideTypeCode)
		parameters.push({ name: "GuideTypeCode", value: guide.GuideTypeCode})

	const resp = await apiService.callV2("[M2].[UpsertGuide]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting guide");
	}
};

export const deleteGuide = async (partId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteGuide]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting guide");
	}
};
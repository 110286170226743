import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CASSETTE_CHOCK_INITIAL_VALUES,
  CassetteChock,
} from "../../../../models/parts/CassetteChock";
import {
  deleteCassetteChock,
  upsertCassetteChock,
} from "../../../../repositories/parts/CassetteChockRepository";
import { PartFields } from "../../PartFields";
import { useCassetteChocks } from "../CassetteChockPage";

type Props = {
  CassetteChockId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({
  CassetteChockId,
  modal,
  onHide,
}: Props) => {
  const [title, setTitle] = useState<string>("CREATE");
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const succesfullyMessage = `The cassette chock was ${modal}d succesfully`;

  const { data: rows, isLoading } = useCassetteChocks(
    CassetteChockId || undefined,
    (error) => setError(error.message)
  );

  const cassette = rows ? rows[0] : null;

  const { control, setValue, reset, handleSubmit } = useForm<CassetteChock>({
    defaultValues: CASSETTE_CHOCK_INITIAL_VALUES,
    mode: "onChange",
  });

  const checkChanges = useCallback(
    (data: CassetteChock) => {
      const hasChanged =
        data.LocationId !== cassette?.LocationId ||
        data.PartName !== cassette?.PartName;
      setHasChanges(hasChanged);
    },
    [cassette]
  );

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteCassetteChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["cassetteChocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertCassetteChock, {
    onSuccess: () => {
      queryClient.invalidateQueries(["cassetteChocks"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
      setHasChanges(false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<CassetteChock> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      deleteMutation.mutate(data.PartId);
    } else {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create") {
        if (cassette) {
          setValue("PartId", cassette?.PartId);
          setValue("PartName", cassette?.PartName);
          setValue("RFIDTag", cassette?.RFIDTag);
          setValue("LocationId", cassette?.LocationId);
        }
      }
    }
  }, [modal, cassette]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as CassetteChock);
  }, [formValues]);

  return (
    <>
      <MesfModal
        title={`${title} CASSETTE CHOCK`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="cassette-chock-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CassetteChock>
                    control={control}
                    disabled={modal === "delete"}
                  />
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              disabled={!hasChanges && modal !== "delete"}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Cassette Chock"
      />
    </>
  );
};

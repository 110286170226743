import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CassetteChock } from "../../models/parts/CassetteChock";

export const getCassetteChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCassetteChocks]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CassetteChock[];
	}
	else {
		throw new Error(resp.message || "Error fetching cassette chocks");
	}
};
export const upsertCassetteChock = async (cassetteChock: CassetteChock) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: cassetteChock.PartId },
		{ name: "PartName", value: cassetteChock.PartName },
		{ name: "LocationId", value: cassetteChock.LocationId },
	];

	const resp = await apiService.callV2("[M2].[UpsertCassetteChock]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting cassette chock");
	}
};

export const deleteCassetteChock = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteCassetteChock]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting cassette chock");
	}
};
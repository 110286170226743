import { Grid, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { BarrelSelector } from "../../../../../../shared/selectors";
import { DONUT_INITIAL_VALUES, Donut } from "../../../../models/parts/Donut";
import { Stand } from "../../../../models/Stand";
import { upsertDonut } from "../../../../repositories/parts/DonutRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  donut: Donut | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const optionsDonutCode = [
  { Code: "R", Name: "Ready" },
  { Code: "U", Name: "Under Repair" },
  { Code: "D", Name: "Damaged" },
  { Code: "M", Name: "Mounted" },
];

export const DonutDetails = ({ donut, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The donut was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<Donut>({
    defaultValues: DONUT_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: donut?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });

  const onChangeRealType = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value.replace(",", ".");
    if (value === "" || /^(\d*\.?\d{0,2}|\d+\.)$/.test(value)) {
      onChange(value);
    }
  };
  const onBlurRealType = (onChange: (value: string) => void, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      onChange(numValue.toFixed(1));
    } else if (value === "") {
      onChange("");
    }
  };

  const checkChanges = useCallback(
    (data: Donut) => {
      const standsMappeds = standsSelected.map((stand: Stand) => ({
        ...stand,
        PartId: donut?.PartId,
        PartName: donut?.PartName,
      }));

      const hasChanged =
        data.LocationId !== donut?.LocationId ||
        data.PartName !== donut?.PartName ||
        data.DonutStatusCode !== donut?.DonutStatusCode ||
        data.BarrelId !== donut?.BarrelId ||
        data.Diameter !== donut?.Diameter ||
        data.NewDiameter !== donut?.NewDiameter ||
        data.ScrapDiameter !== donut?.ScrapDiameter ||
        JSON.stringify(standsMappeds) !== JSON.stringify(standsOfPart);

      setHasChanges(hasChanged);
    },
    [donut, standsSelected]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertDonut, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["donuts"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);

      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Donut> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(DONUT_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (donut) {
        setValue("PartId", donut?.PartId);
        setValue("PartName", donut?.PartName ?? "");
        setValue("LocationId", donut?.LocationId);
        setValue("DonutStatusCode", donut?.DonutStatusCode ?? "R");
        setValue("BarrelId", donut?.BarrelId);
        setValue("Diameter", donut?.Diameter);
        setValue("NewDiameter", donut?.NewDiameter);
        setValue("ScrapDiameter", donut?.ScrapDiameter);
      } else {
        setStandsSelected([]);
      }
    }
  }, [modal, donut]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Donut);
  }, [formValues, standsSelected]);

  return (
    <>
      {donut === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a donut or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Donut>
              control={control}
              locationSizeMd={6}
              disabled={modal === "delete"}
              hideTag
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="DonutStatusCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={
                      optionsDonutCode.find((code) => code.Code === field.value)
                        ?.Name
                    }
                    label="Status"
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="BarrelId"
                control={control}
                rules={{ required: "Barrel is required" }}
                render={({ field, fieldState: { error } }) => (
                  <BarrelSelector
                    label="Barrel"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="Diameter"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="NewDiameter"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="New Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="ScrapDiameter"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Scrap Diameter"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    value={value ?? ""}
                    onChange={(e) => onChangeRealType(e, onChange)}
                    onBlur={() => onBlurRealType(onChange, String(value))}
                    disabled={modal === "delete"}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { FooterButton } from "../../../../../controls/buttons/FooterButton";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";

import SearchIcon from "@material-ui/icons/Search";
import { CatalogSection } from "../../../models/CatalogSection";
import { useGridStyles } from "../../../styles/gridStyles";
import { useSections } from "../CatalogSectionPage";
import { useSectionOptionsFunctions } from "../hooks/useSectionOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { UpsertDeleteModal } from "./UpsertDeleteModal";

export const TableSection = () => {
  const classes = useGridStyles();

  const [filterValue, setFilterValue] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");
  const [sectionId, setSectionId] = useState<number | null>(null);
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useSections(undefined, (error) =>
    setError(error.message)
  );

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useSectionOptionsFunctions({
    setSectionId,
    setShowModal,
  });
  const { columnDefs } = useTableData({
    setSectionId,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setSectionId(data.Id);
    },
  });
  const onRowDoubleClicked = (event: any) => {
    setSectionId((event.data as CatalogSection).SectionId);
    setShowModal("update");
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "sectionContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate) gridApi?.paginationGoToLastPage();
    setShowModal("");
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "sectionContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography
            variant="h5"
            style={{ margin: "1px 0 10px", fontWeight: 600 }}
          >
            Sections
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{ margin: "10px 20px" }}>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            size="small"
            margin="dense"
            value={filterValue}
            onChange={(event) => {
              setFilterValue(event?.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%" }}
        >
          <Grid
            item
            md={12}
            xs={12}
            style={{ padding: "0 15px" }}
            className={classes.relative}
          >
            <Grid item md={12} xs={12} className={classes.table}>
              <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  rowData={rows || []}
                  columnDefs={columnDefs}
                  defaultColDef={columnDefaults}
                  rowHeight={34}
                  headerHeight={34}
                  animateRows={true}
                  pagination={true}
                  rowSelection="single"
                  paginationPageSize={10}
                  getContextMenuItems={getContextMenuItems}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onGridReady={(params) => setGridApi(params.api)}
                  getRowId={(params) =>
                    String((params.data as CatalogSection).SectionId)
                  }
                  loadingOverlayComponent={CenteredLazyLoading}
                />
              </div>
            </Grid>
            <FooterButton
              label="SECTION"
              showCreateModal={showModal === "create"}
              setShowCreateModal={(showCreateModal) =>
                showCreateModal ? setShowModal("create") : setShowModal("")
              }
            />
          </Grid>
        </Grid>
        <UpsertDeleteModal
          SectionId={sectionId}
          onHide={onModalHide}
          modal={showModal}
        />
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

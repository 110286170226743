import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBarrels } from "src/pages/configuration/pages/barrels/CatalogBarrelPage";
import { FooterDetailsButtons } from "../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { BarrelSelector, RollSelector } from "../../../../shared/selectors";
import {
  Rollset,
  ROLLSET_INITIAL_VALUES,
  RollsetRolls,
} from "../../models/Rollset";
import {
  upsertRollset,
  upsertRollsetRolls,
} from "../../repositories/RollsetRepository";

type Props = {
  rollset: Rollset | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const assemblyStatusCodes = [
  { Code: "E", Name: "Empty" },
  { Code: "A", Name: "Assembly In Progress" },
  { Code: "R", Name: "Ready" },
  { Code: "S", Name: "Disassembled In Progress" },
  { Code: "I", Name: "Inhibited" },
];

export const RollsetDetails = ({ rollset, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The rollset was ${modal}d succesfully`;

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<Rollset>({
    defaultValues: ROLLSET_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: barrels } = useBarrels(
    getValues("BarrelId") || rollset?.BarrelId!,
    (error) => setError(error.message)
  );

  const barrel = barrels ? barrels[0] : null;

  const validateRolls = (value: number | null, otherFieldName: any) => {
    const otherValue = watch(otherFieldName);
    return (
      value !== otherValue ||
      value === null ||
      value === undefined ||
      `Cannot be the same as ${
        otherFieldName === "UpperRollId" ? "Upper" : "Lower"
      } Roll`
    );
  };

  const queryClient = useQueryClient();
  const upsertRollsMutation = useMutation(upsertRollsetRolls, {
    onSuccess: () => {
      queryClient.invalidateQueries(["rollsets"]);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertRollset, {
    onSuccess: async (id: number) => {
      if (id) {
        upsertRollsMutation.mutate({
          RollsetId: id,
          PartId: getValues("UpperRollId"),
          IsDefaultUpperRoll: true,
        } as RollsetRolls);
      }
      if (id) {
        upsertRollsMutation.mutate({
          RollsetId: id,
          PartId: getValues("LowerRollId"),
          IsDefaultLowerRoll: true,
        } as RollsetRolls);
      }

      queryClient.invalidateQueries(["rollsets"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Rollset> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") upsertMutation.mutate(data);
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(ROLLSET_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (rollset) {
        setValue("RollsetId", rollset?.RollsetId);
        setValue("BarrelId", rollset?.BarrelId);
        setValue("UpperRollId", rollset?.UpperRollId);
        setValue("LowerRollId", rollset?.LowerRollId);
      }
    }
  }, [modal, rollset]);

  return (
    <>
      {rollset === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a rollset</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Controller
                name="BarrelId"
                control={control}
                render={({ field }) => (
                  <BarrelSelector
                    label="Barrel"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="UpperRollId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateRolls(value, "LowerRollId"),
                  },
                  deps: ["LowerRollId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <RollSelector
                    label="Upper Roll"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LowerRollId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateRolls(value, "UpperRollId"),
                  },
                  deps: ["UpperRollId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <RollSelector
                    label="Lower Roll"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isDirty}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

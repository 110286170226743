import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { UniversalRoll } from "../../../../configuration/models/parts/UniversalRoll";
import { optionsStatusCodes } from "../../../../configuration/pages/parts/universal-roll/components/UniversalRollDetails";
import { Part } from "../../models/Part";
import { getUniversalRolls } from "../../repositories/parts/UniversalRollRepository";

type useRollsProps = {
  partId: number;
  onError: (error: Error) => void;
  onSuccess?: (data: UniversalRoll[]) => void;
};

export const useRolls = ({ partId, onError, onSuccess }: useRollsProps) => {
  return useQuery<UniversalRoll[], Error>({
    queryKey: ["universal-rolls", partId],
    queryFn: () => getUniversalRolls(partId),
    onError: onError,
    onSuccess: onSuccess,
  });
};

type Props = {
  show: boolean;
  part: Part | null;
};

export const SpecificUniversalRollData = ({ show, part }: Props) => {
  const [error, setError] = useState("");
  const [roll, setRoll] = useState<UniversalRoll>();

  const { data: rolls } = useRolls({
    partId: part?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data: UniversalRoll[]) => {
      setRoll(data[0]);
    },
  });

  return (
    <>
      {show && (
        <Grid container>
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: 10,
              padding: 10,
              margin: "0 0 10px",
              width: "100%"
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value="Universal"
                  label="Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={
                    optionsStatusCodes.find(
                      (status) => status.Code === roll?.RollStatusCode
                    )?.Name || ""
                  }
                  label="Status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={roll?.DonutPartName || ""}
                  label="Donut"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={roll?.ArborPartName || ""}
                  label="Arbor"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

export interface CatalogSectionFamily {
	SectionFamilyId: number | null;
	FamilyName: string;
	IsActive: boolean | null;
}

export const CATALOG_SECTION_FAMILY_INITIAL_VALUES: CatalogSectionFamily = {
	SectionFamilyId: null,
	FamilyName: '',
	IsActive: true
};
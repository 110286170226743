import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ConfirmationDialogRaw } from "../../controls/modals/ConfirmationDialogRaw";
import { Donut } from "../models/Donut";
import { getDonuts } from "../repositories/DonutRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

const useDonuts = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Donut[], Error>({
    queryKey: ["donuts", partId],
    queryFn: () => getDonuts(partId),
    onError: onError,
  });
};

export const DonutSelector = ({ label, value, onChange, disabled }: Props) => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<Donut | null>(null);

  const { data: donuts, isLoading } = useDonuts(undefined, (error) =>
    setError(error.message)
  );

  const selectedDonut = donuts?.find((donut) => donut.PartId === value) || null;

  const inUse = (props: Donut | null) => {
    if (props?.InUse && props?.PartId !== value) return true;
    return false;
  };

  const handleConfirmationClose = (result?: string) => {
    setMessage("");
    if (result === "OK") {
      onChange(selectedValue?.PartId ?? null);
      setSelectedValue(null);
      return;
    }
  };

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={donuts || []}
        getOptionLabel={(option) => option.PartName}
        renderOption={(props, option) => {
          return (
            <span style={{ color: inUse(props) ? "red" : "black" }}>
              {props.PartName}
              {inUse(props) ? " - In Use" : ""}
            </span>
          );
        }}
        getOptionSelected={(option, value) => option.PartId === value.PartId}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedDonut}
        onChange={(event, newValue) => {
          if (inUse(newValue)) {
            setMessage(
              `The donut ${newValue?.PartName} is already in use by another roll, do you want to disassemble it?`
            );
            setSelectedValue(newValue);
          } else onChange(newValue?.PartId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading donuts"
      />
      <ConfirmationDialogRaw
        title="CONFIRMATION"
        message={message}
        open={message !== ""}
        onClose={handleConfirmationClose}
      />
    </>
  );
};

import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { EdgerRoll } from "../../../../models/parts/EdgerRoll";
import { RollStatusTag } from "../../../../shared/components/RollStatusTag";
import { optionsStatusCodes } from "../components/EdgerRollDetails";

type Props = {
  setSelectedRowData: (roll: EdgerRoll) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "Diameter",
      headerName: "Diameter",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "BarrelName",
      headerName: "Barrel",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RollStatusCode",
      headerName: "Status",
      minWidth: 20,
      maxWidth: 70,
      flex: 1,
      cellStyle: cellStyle,
      valueGetter: (params) => optionsStatusCodes.find(
        (code) => code.Code === (params.data as EdgerRoll).RollStatusCode
      )?.Name,
      cellRenderer: RollStatusTag,
      cellRendererParams: (params: ICellRendererParams) => ({
        status: optionsStatusCodes.find(
          (code) => code.Code === (params.data as EdgerRoll).RollStatusCode
        ),
      }),
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSelectedRowData,
        id: params.data as EdgerRoll,
        setShowModal: setShowModal,
        edit: false,
        showContextMenu: () =>
          showContextMenu(event, params.data, "edgerRollContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};

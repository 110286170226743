export interface EdgerRoll {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null,
	BarrelName: string | null;
	BarrelId: number | null,
	RollStatusCode: string | null;
	RollTypeCode: string | null;
	Diameter: number | null;
	NewDiameter: number | null;
	ScrapDiameter: number | null;
	IsActive: boolean | null;
}

export const EDGER_ROLL_INITIAL_VALUES: EdgerRoll = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	BarrelName: '',
	BarrelId: null,
	RollStatusCode: 'R',
	RollTypeCode: 'E',
	Diameter: null,
	NewDiameter: null,
	ScrapDiameter: null,
	IsActive: true
};
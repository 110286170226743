import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Location } from "../models/Location";
import { getLocations } from "../repositories/LocationRepository";

type useLocationsProps = {
  onError: (error: Error) => void;
  onlyStands?: boolean;
  locationId?: number;
};

const useLocations = ({
  onError,
  onlyStands,
  locationId,
}: useLocationsProps) => {
  return useQuery<Location[], Error>({
    queryKey: ["locations", locationId, onlyStands],
    queryFn: () => getLocations(locationId, onlyStands),
    onError: onError,
  });
};

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled?: boolean;
  onlyStands?: boolean;
};

export const LocationSelector = ({
  label,
  value,
  onChange,
  disabled = false,
  onlyStands = false,
}: Props) => {
  const [error, setError] = useState("");

  const { data: locations, isLoading } = useLocations({
    onError: (error) => setError(error.message),
    onlyStands: onlyStands,
  });

  const selectedValue =
    locations?.find((location) => location.LocationId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={locations || []}
        getOptionLabel={(option) => option.LocationName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.LocationId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading guides"
      />
    </>
  );
};

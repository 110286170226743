import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { CatalogSection } from "../../models/CatalogSection";
import { getSections } from "../../repositories/CatalogSectionRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TableSection } from "./components/TableSection";

export const useSections = (
  sectionId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<CatalogSection[], Error>({
    queryKey: ["sections", sectionId],
    queryFn: () => getSections(sectionId),
    onError: onError,
  });
};

export const CatalogSectionPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableSection />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

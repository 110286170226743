import { MenuOptionType } from "@dexteel/mesf-core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import { CatalogSection } from "../../../models/CatalogSection";

type Props = {
  setSectionFamilyId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
};
export const useSectionOptionsFunctions = ({
  setSectionFamilyId,
  setShowModal,
}: Props) => {
  const getMenuOptions = (data: CatalogSection) => {
    let options: MenuOptionType[] = [];
    options = options.concat([
      {
        name: "New Section Family",
        key: "new_section_family",
        onClick: () => {
          setShowModal("create");
        },
        icon: <PlaylistAddIcon />,
      },
    ]);
    if (data) {
      options = options.concat([
        {
          name: "Edit Section Family",
          key: "edit_section_family",
          onClick: () => {
            setSectionFamilyId(data.SectionId!);
            setShowModal("update");
          },
          icon: <EditIcon />,
        },
        {
          name: "Delete Section Family",
          key: "delete_section_family",
          onClick: () => {
            setSectionFamilyId(data.SectionId!);
            setShowModal("delete");
          },
          icon: <DeleteIcon />,
        },
      ]);
    }
    return options;
  };
  return {
    getMenuOptions,
  };
};

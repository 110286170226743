import { Grid, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { GuideTypeSelector } from "../../../../../../shared/selectors";
import { GUIDE_INITIAL_VALUES, Guide } from "../../../../models/parts/Guide";
import { Stand } from "../../../../models/Stand";
import { upsertGuide } from "../../../../repositories/parts/GuideRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  guide: Guide | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const GuideDetails = ({ guide, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The guide was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<Guide>({
    defaultValues: GUIDE_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: guide?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });

  const checkChanges = useCallback(
    (data: Guide) => {
      const standsMappeds = standsSelected.map((stand: Stand) => ({
        ...stand,
        PartId: guide?.PartId,
        PartName: guide?.PartName,
      }));

      const hasChanged =
        data.LocationId !== guide?.LocationId ||
        data.PartName !== guide?.PartName ||
        data.GuideTypeCode !== guide?.GuideTypeCode ||
        JSON.stringify(standsMappeds) !== JSON.stringify(standsOfPart);

      setHasChanges(hasChanged);
    },
    [guide, standsSelected]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertGuide, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["guides"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);

      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Guide> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(GUIDE_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (guide) {
        setValue("PartId", guide?.PartId);
        setValue("PartName", guide?.PartName ?? "");
        setValue("RFIDTag", guide?.RFIDTag ?? "");
        setValue("LocationId", guide?.LocationId);
        setValue(
          "GuideTypeCode",
          guide?.GuideTypeCode ? guide?.GuideTypeCode : "I"
        );
      } else {
        setStandsSelected([]);
      }
    }
  }, [modal, guide]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Guide);
  }, [formValues, standsSelected]);

  return (
    <>
      {guide === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a guide or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<Guide>
              control={control}
              locationSizeMd={6}
              disabled={modal === "delete"}
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="GuideTypeCode"
                control={control}
                rules={{ required: "Type is required" }}
                render={({ field, fieldState: { error } }) => (
                  <GuideTypeSelector
                    label="Type"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

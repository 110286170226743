import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CATALOG_SECTION_FAMILY_INITIAL_VALUES,
  CatalogSectionFamily,
} from "../../../models/CatalogSectionFamily";
import {
  deleteSectionFamily,
  upsertSectionFamily,
} from "../../../repositories/CatalogSectionFamilyRepository";
import { useSectionFamilies } from "../CatalogSectionFamilyPage";

type Props = {
  SectionId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ SectionId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The section family was ${modal}d succesfully`;

  const { data, isLoading } = useSectionFamilies(
    SectionId ?? undefined,
    (error) => setError(error.message)
  );
  const sectionFamily = Array.isArray(data) ? data[0] ?? null : data;

  const { control, setValue, reset, handleSubmit } =
    useForm<CatalogSectionFamily>({
      defaultValues: CATALOG_SECTION_FAMILY_INITIAL_VALUES,
    });

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteSectionFamily, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sectionFamilies"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });
  const upsertMutation = useMutation(upsertSectionFamily, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sectionFamilies"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<CatalogSectionFamily> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      deleteMutation.mutate(data.SectionFamilyId);
    } else {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        if (sectionFamily && !isLoading) {
          setValue("SectionFamilyId", sectionFamily?.SectionFamilyId);
          setValue("FamilyName", sectionFamily?.FamilyName);
        }
    }
  }, [modal, isLoading]);

  return (
    <>
      <MesfModal
        title={`${title} SECTION FAMILY`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="section-family-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="FamilyName"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            disabled={modal === "delete"}
                            onChange={onChange}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Section Family"
      />
    </>
  );
};

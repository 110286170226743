import { ErrorModal, useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, IconButton, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowSelectedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../../controls/ag-grid/components/GridDefinitions";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { Arbor } from "../../../../models/parts/Arbor";
import { deleteArbor } from "../../../../repositories/parts/ArborRepository";
import { useGridStyles } from "../../../../styles/gridStyles";
import { DeletePart } from "../../DeletePart";
import { useArbors } from "../ArborPage";
import { useArborOptionsFunctions } from "../hooks/useArborOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { ArborDetails } from "./ArborDetails";

export const TableArbor = () => {
  const classes = useGridStyles();

  const [selectedRowData, setSelectedRowData] = useState<Arbor | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");
  const [error, setError] = useState("");

  const { data: rows, isLoading } = useArbors(undefined, (error) =>
    setError(error.message)
  );

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useArborOptionsFunctions({
    setSelectedRowData,
    setShowModal,
  });
  const { columnDefs } = useTableData({
    setSelectedRowData,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setSelectedRowData(data);
    },
  });

  const onRowSelected = (event: RowSelectedEvent) => {
    if (event.node.isSelected()) {
      setSelectedRowData(event.data);
      setShowModal("update");
    }
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "arborContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = (shouldUpdate: boolean, close: boolean) => {
    if (shouldUpdate) gridApi?.paginationGoToLastPage();
    if (close) {
      setShowModal("");
      setSelectedRowData(null);
      if (gridApi) gridApi?.deselectAll();
    }
  };

  const handleNewArbor = () => {
    if (gridApi) gridApi.deselectAll();
    setShowModal("create");
    setSelectedRowData(null);
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "arborContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Arbors
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}
          style={{ width: "100%", padding: "0 10px" }}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <div
              style={{ height: "87vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <AgGridReact<Arbor>
                rowData={rows || []}
                columnDefs={columnDefs}
                defaultColDef={columnDefaults}
                rowHeight={34}
                headerHeight={34}
                animateRows
                pagination
                rowSelection="single"
                paginationAutoPageSize
                getContextMenuItems={getContextMenuItems}
                onGridReady={(params) => setGridApi(params.api)}
                getRowId={(params) => String(params.data.PartId)}
                loadingOverlayComponent={CenteredLazyLoading}
                onRowSelected={onRowSelected}
              />
              <IconButton
                onClick={handleNewArbor}
                color="primary"
                style={{
                  position: "absolute",
                  padding: 0,
                  bottom: 53,
                  left: 63,
                }}
              >
                <PlaylistAddIcon style={{ width: 30, height: 30 }} />
              </IconButton>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div
              style={{ height: "87vh", width: "100%" }}
              className={`${classes.table} ag-theme-alpine`}
            >
              <ArborDetails
                arbor={selectedRowData}
                onHide={onModalHide}
                modal={showModal}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <DeletePart
        show={showModal === "delete"}
        part={{
          Id: selectedRowData?.PartId!,
          Name: selectedRowData?.PartName!,
          Entity: "Guide",
        }}
        onHide={(update: boolean) => {
          onModalHide(update, true);
          setShowModal(update ? "" : "update");
        }}
        deleteFunction={deleteArbor}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

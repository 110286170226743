import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setBarrelPassShapes } from "src/pages/configuration/repositories/BarrelPassShapeRepository";
import { PassShapeSelector } from "src/shared/selectors/PassShapeSelector";
import { FooterDetailsButtons } from "../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import {
  CATALOG_BARREL_INITIAL_VALUES,
  CatalogBarrel,
} from "../../../models/CatalogBarrel";
import { GUIDE_INITIAL_VALUES } from "../../../models/parts/Guide";
import { upsertBarrel } from "../../../repositories/CatalogBarrelRepository";

const useStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  barrel: CatalogBarrel | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const BarrelDetails = ({ barrel, modal, onHide }: Props) => {
  const classes = useStyles();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const succesfullyMessage = `The barrel was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit, getValues } =
    useForm<CatalogBarrel>({
      defaultValues: GUIDE_INITIAL_VALUES,
      mode: "onChange",
    });

  const checkChanges = useCallback(
    (data: CatalogBarrel) => {
      const hasChanged =
        data.BarrelName !== barrel?.BarrelName ||
        data.PassShapeAmmount !== barrel?.PassShapeAmmount ||
        data.Comments !== barrel?.Comments;

      setHasChanges(hasChanged);
    },
    [barrel]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertBarrel, {
    onSuccess: async (id: number) => {
      if (id && getValues("PassShapeId")) {
        const resp = await setBarrelPassShapes(
          id,
          getValues("PassShapeId")!,
          getValues("PassShapeAmmount")!
        );
        if (!resp.ok) setError(resp.message);
      }
      queryClient.invalidateQueries(["barrels"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<CatalogBarrel> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(CATALOG_BARREL_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (barrel) {
        setValue("BarrelId", barrel?.BarrelId);
        setValue("BarrelName", barrel?.BarrelName ?? "");
        setValue("PassShapeId", barrel?.PassShapeId);
        setValue("PassShapeAmmount", barrel?.PassShapeAmmount);
        setValue("Comments", barrel?.Comments ?? "");
      }
    }
  }, [modal, barrel]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as CatalogBarrel);
  }, [formValues]);

  return (
    <>
      {barrel === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a barrel or create a new one</Typography>
        </div>
      ) : (
        <form id="upsert-barrel-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Controller
                name={"BarrelName"}
                control={control}
                rules={{ required: "Name is required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Controller
                name={"PassShapeAmmount"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Ammount"
                    type="number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value ?? 0}
                    onChange={(e) => {
                      if (Number(e.target.value) > 0)
                        onChange(Number(e.target.value));
                    }}
                    error={!!error}
                    helperText={error?.message}
                    disabled={!getValues("PassShapeId")}
                  />
                )}
              />
            </Grid>
            <Grid item md={9} sm={12} xs={12}>
              <Controller
                name={"PassShapeId"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PassShapeSelector
                    label="Pass Shape"
                    value={value}
                    onChange={(newValue: number) => {
                      setValue("PassShapeAmmount", getValues("PassShapeId") ? 1 : 0);
                      onChange(newValue);
                    }}
                  />
                )}
              />
            </Grid>
            {/* <BarrelPassShapesGrid
              disabled={modal === "delete"}
              barrel={barrel}
            /> */}
            <Grid item md={12} sm={12} style={{ marginBottom: 10 }}>
              <Controller
                name="Comments"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      label="Comments"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={field?.value}
                      maxRows={3}
                      minRows={3}
                      multiline
                      autoComplete="off"
                      disabled={modal === "delete"}
                      onChange={(event) => {
                        if (event.target.value.length <= 200) {
                          field.onChange(event.target.value);
                        }
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                    {(getValues("Comments")?.length as number) !== 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        {`${getValues("Comments")?.length ?? 0}/200 max.`}
                      </span>
                    )}
                    {(getValues("Comments")?.length as number) == 200 && (
                      <span
                        className={classes.infoLabel}
                        style={{ marginLeft: 5 }}
                      >
                        Max. 200
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: "0px 15px" }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

import { Grid, TextField, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FooterDetailsButtons } from "../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { Roll } from "../../../../shared/models/Roll";
import { Rollset } from "../../../../shared/models/Rolllset";
import {
  CassetteSelector,
  ChockSelector,
  GuideSelector,
  LocationSelector,
  RollSelector,
  RollsetSelector,
  SectionSelector,
} from "../../../../shared/selectors";
import { FilterRollModal } from "./FilterRollModal";

import {
  Carrier,
  CARRIER_INITIAL_VALUES,
} from "../../../configuration/models/parts/Carrier";
import { upsertCarrier } from "../../../configuration/repositories/parts/CarrierRepository";

type Props = {
  carrier: Carrier | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const assemblyStatusCodes = [
  { Code: "E", Name: "Empty" },
  { Code: "A", Name: "Assembly In Progress" },
  { Code: "R", Name: "Ready" },
  { Code: "S", Name: "Disassembled In Progress" },
  { Code: "I", Name: "Inhibited" },
];

export const CarrierAssemblyDetails = ({ carrier, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [selectedRolls, setSelectedRolls] = useState<Roll[]>();
  const [selectedRollset, setSelectedRollset] = useState<Rollset>();

  const succesfullyMessage = `The carrier was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit, watch, getValues } =
    useForm<Carrier>({
      defaultValues: CARRIER_INITIAL_VALUES,
      mode: "onChange",
    });

  const validateRolls = (value: number | null, otherFieldName: any) => {
    const otherValue = watch(otherFieldName);
    return (
      value !== otherValue ||
      value === null ||
      value === undefined ||
      `Cannot be the same as ${
        otherFieldName === "UpperRollId" ? "Upper" : "Lower"
      } Roll`
    );
  };

  const validateChocks = (
    value: number | null,
    otherValues: { Chock: "LM" | "UM" | "LO" | "UO" }[]
  ) => {
    const firstValue = watch(`${otherValues[0].Chock}ChockId`);
    const secondValue = watch(`${otherValues[1].Chock}ChockId`);
    const thirdValue = watch(`${otherValues[2].Chock}ChockId`);
    let sameValue;
    if (value === firstValue) sameValue = otherValues[0].Chock;
    if (value === secondValue) sameValue = otherValues[1].Chock;
    if (value === thirdValue) sameValue = otherValues[2].Chock;
    return (
      value === null ||
      value === undefined ||
      !sameValue ||
      `Cannot be the same as ${sameValue} Chock`
    );
  };

  const checkChanges = useCallback(
    (data: Carrier) => {
      const hasChanged =
        data.CassetteId !== carrier?.CassetteId ||
        data.UpperRollId !== carrier?.UpperRollId ||
        data.LowerRollId !== carrier?.LowerRollId ||
        data.UOChockId !== carrier?.UOChockId ||
        data.LOChockId !== carrier?.LOChockId ||
        data.UMChockId !== carrier?.UMChockId ||
        data.EntryGuideId !== carrier?.EntryGuideId ||
        data.ExitGuideId !== carrier?.ExitGuideId ||
        data.Bait !== carrier?.Bait ||
        data.WeighUp !== carrier?.WeighUp ||
        data.ScheduledSectionId !== carrier?.ScheduledSectionId ||
        data.ScheduledLocationId !== carrier?.ScheduledLocationId;

      setHasChanges(hasChanged);
    },
    [carrier]
  );

  const handleHideFilter = (id?: number) => {
    if (selectedRollset) {
      setValue("UpperRollId", selectedRollset.UpperRollId);
      setValue("LowerRollId", selectedRollset.LowerRollId);
    } else if (selectedRolls) {
      setValue("UpperRollId", selectedRolls ? selectedRolls[0].PartId : null);
      setValue("LowerRollId", selectedRolls ? selectedRolls[1].PartId : null);
      if (id) setValue("RollsetId", id);
    }
    setSelectedRolls(undefined);
    setSelectedRollset(undefined);
    setShowFilterModal(false);
  };

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertCarrier, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["carriers"]);
      setNotificationMessage(succesfullyMessage);
      onHide(true, false);
      setHasChanges(false);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<Carrier> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") upsertMutation.mutate(data);
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset(CARRIER_INITIAL_VALUES);
      setIsSubmitLoading(false);
      if (carrier) {
        setValue("PartId", carrier?.PartId);
        setValue("PartName", carrier?.PartName ?? "");
        setValue("AssemblyStatusCode", carrier?.AssemblyStatusCode);
        setValue("UpperRollId", carrier?.UpperRollId);
        setValue("LowerRollId", carrier?.LowerRollId);
        setValue("RollsetId", carrier?.RollsetId);
        setValue("UMChockId", carrier?.UMChockId);
        setValue("LMChockId", carrier?.LMChockId);
        setValue("UOChockId", carrier?.UOChockId);
        setValue("LOChockId", carrier?.LOChockId);
        setValue("EntryGuideId", carrier?.EntryGuideId);
        setValue("ExitGuideId", carrier?.ExitGuideId);
        setValue("CassetteId", carrier?.CassetteId);
        setValue("Bait", carrier?.Bait);
        setValue("WeighUp", carrier?.WeighUp);
        setValue("ScheduledSectionId", carrier?.ScheduledSectionId);
        setValue("ScheduledLocationId", carrier?.ScheduledLocationId);
      }
    }
  }, [modal, carrier]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as Carrier);
  }, [formValues]);

  return (
    <>
      {carrier === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a carrier</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Controller
                name="ScheduledSectionId"
                control={control}
                render={({ field }) => (
                  <SectionSelector
                    label="Section"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="ScheduledLocationId"
                control={control}
                render={({ field }) => (
                  <LocationSelector
                    label="Stand Position"
                    value={field.value}
                    onChange={field.onChange}
                    onlyStands
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="CassetteId"
                control={control}
                render={({ field }) => (
                  <CassetteSelector
                    label="Cassette"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="RollsetId"
                control={control}
                render={({ field }) => (
                  <RollsetSelector
                    filterSearch={{
                      SectionId: getValues("ScheduledSectionId"),
                      StandId: getValues("ScheduledLocationId"),
                      RollTypeCode: null,
                    }}
                    label="Rollset"
                    value={field.value}
                    onChange={field.onChange}
                    setShowFilterModal={setShowFilterModal}
                    disabled={
                      getValues("ScheduledSectionId") &&
                      getValues("ScheduledLocationId")
                        ? false
                        : true
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="UpperRollId"
                control={control}
                render={({ field }) => (
                  <RollSelector
                    label="Upper Roll"
                    value={field.value}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LowerRollId"
                control={control}
                render={({ field }) => (
                  <RollSelector
                    label="Lower Roll"
                    value={field.value}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="UMChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UO" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["LMChockId", "LOChockId", "UMChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Upper Machine Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LMChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "UM" },
                        { Chock: "UO" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["UMChockId", "UOChockId", "LOChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Lower Machine Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="UOChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UM" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["LMChockId", "UMChockId", "LOChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Upper Operation Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LOChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UO" },
                        { Chock: "UM" },
                      ]),
                  },
                  deps: ["LMChockId", "UOChockId", "UMChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Lower Operation Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="EntryGuideId"
                control={control}
                render={({ field }) => (
                  <GuideSelector
                    label="Entry Guide"
                    typeCode="I"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="ExitGuideId"
                control={control}
                render={({ field }) => (
                  <GuideSelector
                    label="Delivery Guide"
                    typeCode="O"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="Bait"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    label="Bait"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="WeighUp"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    label="Weigh Up"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
      <FilterRollModal
        show={showFilterModal}
        onHide={handleHideFilter}
        filterSearch={{
          SectionId: getValues("ScheduledSectionId"),
          StandId: getValues("ScheduledLocationId"),
          RollTypeCode: null,
        }}
        selectedRolls={selectedRolls}
        setSelectedRolls={setSelectedRolls}
        selectedRollset={selectedRollset}
        setSelectedRollset={setSelectedRollset}
      />
    </>
  );
};

import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Shape } from "../models/Shape";

export const getShapes = async (shapeId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (shapeId)
		parameters.push({ name: "ShapeId", value: shapeId });

	const resp = await apiService.callV2("[M2].[GetShapes]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Shape[];
	}
	else {
		throw new Error(resp.message || "Error fetching shapes");
	}
};
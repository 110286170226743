import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { Carrier } from "../../../../models/parts/Carrier";
import { assemblyStatusCodes } from "../components/CarrierDetails";

type Props = {
  setSelectedRowData: (carrier: Carrier) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setSelectedRowData,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RFIDTag",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "AssemblyStatusCode",
      headerName: "Assembly Status",
      minWidth: 20,
      flex: 1,
      valueGetter: (params) =>
        assemblyStatusCodes.find(
          (code) => code.Code === (params.data as Carrier).AssemblyStatusCode
        )?.Name,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setSelectedRowData,
        id: params.data as Carrier,
        setShowModal: setShowModal,
        edit: false,
        showContextMenu: () =>
          showContextMenu(event, params.data, "carrierContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      maxWidth: 180,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};

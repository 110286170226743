import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { PassShape } from "../models/PassShape";

export const getPassShapes = async (sectionId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (sectionId) parameters.push({ name: "PassShapeId", value: sectionId });

  const resp = await apiService.callV2("[M2].[GetPassShapes]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as PassShape[];
  } else {
    throw new Error(resp.message || "Error fetching pass shapes");
  }
};

export interface Rollset {
	RollsetId: number | null;
	BarrelId: number | null;
    BarrelName: string;
    UpperRollId: number | null;
    LowerRollId: number | null;
}

export const ROLLSET_INITIAL_VALUES: Rollset = {
	RollsetId: null,
	BarrelId: null,
	BarrelName: '',
	UpperRollId: null,
	LowerRollId: null,
};

export interface RollsetRolls {
	RollsetId: number | null;
    PartId: number | null;
    IsDefaultUpperRoll: boolean;
    IsDefaultLowerRoll: boolean;
}
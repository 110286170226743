import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldError } from "react-hook-form";

export const optionsTypeCode = [
  { Code: "T", Name: "Cassette" },
  { Code: "E", Name: "Edger" },
  { Code: "D", Name: "Donut" },
];

type Props = {
  label: string;
  value: string | null;
  onChange: Function;
  fieldError?: FieldError | undefined;
  disabled: boolean;
};

export const PassShapeTypeSelector = ({
  label,
  value,
  onChange,
  fieldError,
  disabled,
}: Props) => {
  const selectedType =
    optionsTypeCode.find((code) => code.Code === value) || null;

  return (
    <Autocomplete
      id="clear-on-escape"
      clearOnEscape
      options={
        value === "B"
          ? optionsTypeCode.filter((code) => code.Code !== "T")
          : optionsTypeCode
      }
      getOptionLabel={(option) => option.Name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          error={!!fieldError}
          helperText={fieldError?.message}
          size="small"
        />
      )}
      value={selectedType}
      onChange={(event, newValue) => {
        onChange(newValue?.Code ?? null);
      }}
      disabled={disabled}
    />
  );
};

import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Shape } from "../models/Shape";
import { getShapes } from "../repositories/ShapeRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled?: boolean;
};

const useShapes = (shapeId?: number, onError?: (error: Error) => void) => {
  return useQuery<Shape[], Error>({
    queryKey: ["shapes", shapeId],
    queryFn: () => getShapes(shapeId),
    onError: onError,
  });
};

export const ShapeSelector = ({ label, value, onChange, disabled = false}: Props) => {
  const [error, setError] = useState("");

  const { data: shapes, isLoading } = useShapes(undefined, (error) =>
    setError(error.message)
  );

  const selectedShape =
    shapes?.find((shape) => shape.ShapeId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={shapes || []}
        getOptionLabel={(option) => option.ShapeName}
        getOptionSelected={(option, value) => option.ShapeId === value.ShapeId}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedShape}
        onChange={(event, newValue) => {
          onChange(newValue?.ShapeId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading shapes"
      />
    </>
  );
};

import { Grid, TextField } from "@material-ui/core";
import { Part } from "../models/Part";
import {
  SpecificArborData,
  SpecificCarrierData,
  SpecificCassetteData,
  SpecificChockData,
  SpecificDonutData,
  SpecificEdgerRollData,
  SpecificGuideData,
  SpecificUniversalRollData,
} from "./parts-panels/specifics.datas";

type Props = {
  part: Part | null;
  showDetails: keyof typeof components;
};

const components = {
  A: SpecificArborData,
  H: SpecificCarrierData,
  T: SpecificCassetteData,
  C: SpecificChockData,
  D: SpecificDonutData,
  E: SpecificEdgerRollData,
  G: SpecificGuideData,
  U: SpecificUniversalRollData,
};

export const PartDetails = ({ part, showDetails }: Props) => {
  const hideTag = part?.PartTypeCode === "D";

  const SpecificComponent = components[showDetails];

  return (
    <>
      <Grid container>
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: 10,
            padding: 10,
            margin: "0 0 10px",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={part?.PartName || ""}
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            {!hideTag && (
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  value={part?.RFIDTag}
                  label="Tag"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                />
              </Grid>
            )}
            <Grid item md={!hideTag ? 12 : 6} sm={12} xs={12}>
              <TextField
                value={part?.LocationName || ""}
                label="Location"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </div>
        {SpecificComponent && <SpecificComponent show={true} part={part} />}
      </Grid>
    </>
  );
};

import { Grid, TextField, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FooterDetailsButtons } from "../../../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { DonutSelector } from "../../../../../../shared/selectors";
import { ArborSelector } from "../../../../../../shared/selectors/ArborSelector";
import {
  UNIVERSAL_ROLL_INITIAL_VALUES,
  UniversalRoll,
} from "../../../../models/parts/UniversalRoll";
import { Stand } from "../../../../models/Stand";
import { upsertUniversalRoll } from "../../../../repositories/parts/UniversalRollRepository";
import { setStandsToPart } from "../../../../repositories/StandRepository";
import { PartFields } from "../../PartFields";
import { StandGrid, useStandsByPart } from "../../StandGrid";

type Props = {
  roll: UniversalRoll | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean, close: boolean) => void;
};

export const optionsStatusCodes = [
  { Code: "B", Name: "Blank" },
  { Code: "R", Name: "Ready" },
  { Code: "P", Name: "In Process" },
  { Code: "I", Name: "Installed" },
  { Code: "D", Name: "Damaged" },
];

export const UniversalRollDetails = ({ roll, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [standsSelected, setStandsSelected] = useState<Stand[]>([]);

  const succesfullyMessage = `The roll was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<UniversalRoll>({
    defaultValues: UNIVERSAL_ROLL_INITIAL_VALUES,
    mode: "onChange",
  });

  const { data: standsOfPart } = useStandsByPart({
    partId: roll?.PartId!,
    onError: (error) => setError(error.message),
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setStandsSelected([...data]);
      }
    },
  });

  const checkChanges = useCallback(
    (data: UniversalRoll) => {
      const standsMappeds = standsSelected.map((stand: Stand) => ({
        ...stand,
        PartId: roll?.PartId,
        PartName: roll?.PartName,
      }));

      const hasChanged =
        data.LocationId !== roll?.LocationId ||
        data.PartName !== roll?.PartName ||
        data.DonutPartId !== roll?.DonutPartId ||
        data.ArborPartId !== roll?.ArborPartId ||
        data.RollStatusCode !== roll?.RollStatusCode ||
        JSON.stringify(standsMappeds) !== JSON.stringify(standsOfPart);

      setHasChanges(hasChanged);
    },
    [roll, standsSelected]
  );

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(upsertUniversalRoll, {
    onSuccess: async (id: number) => {
      queryClient.invalidateQueries(["universalRolls"]);
      setNotificationMessage(succesfullyMessage);
      setHasChanges(false);
      onHide(true, false);
      if (id) {
        const resp = await setStandsToPart(id, standsSelected);
        if (!resp.ok) setError(resp.message);
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => setIsSubmitLoading(false),
  });

  const onSubmit: SubmitHandler<UniversalRoll> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "delete") {
      upsertMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (modal !== "delete") {
      reset();
      setIsSubmitLoading(false);
      if (roll) {
        setValue("PartId", roll?.PartId);
        setValue("PartName", roll?.PartName);
        setValue("RFIDTag", roll?.RFIDTag);
        setValue("LocationId", roll?.LocationId);
        setValue("DonutPartId", roll?.DonutPartId);
        setValue("ArborPartId", roll?.ArborPartId);
        setValue("RollStatusCode", roll?.RollStatusCode ?? "R");
      } else {
        setStandsSelected([]);
      }
    }
  }, [modal, roll]);

  const formValues = useWatch({ control });

  useEffect(() => {
    checkChanges(formValues as UniversalRoll);
  }, [formValues, standsSelected]);

  return (
    <>
      {roll === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a roll or create a new one</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <PartFields<UniversalRoll>
              control={control}
              locationSizeMd={6}
              disabled={modal === "delete"}
            />
            <Grid item xs={12} md={6}>
              <Controller
                name="ArborPartId"
                control={control}
                render={({ field }) => (
                  <ArborSelector
                    label="Arbor"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="DonutPartId"
                control={control}
                render={({ field }) => (
                  <DonutSelector
                    label="Donut"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={modal === "delete"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="RollStatusCode"
                control={control}
                rules={{ required: "Type is required" }}
                render={({ field }) => (
                  <TextField
                    value={
                      optionsStatusCodes.find(
                        (code) => code.Code === field.value
                      )?.Name
                    }
                    label="Status"
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </Grid>
            <StandGrid
              onSelectionChanged={setStandsSelected}
              disabled={modal === "delete"}
              standsOfPart={standsSelected}
            />
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!hasChanges}
                onHide={() => onHide(false, true)}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};

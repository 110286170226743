export interface Cassette {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null;
    PassShapeId: number | null;
	RivCassetteChockId: number | null;
	RivCassetteChockName: string | null;
	AveCassetteChockId: number | null;
	AveCassetteChockName: string | null;
	IsActive: boolean | null;
}

export const CASSETTE_INITIAL_VALUES: Cassette = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	PassShapeId: null,
	RivCassetteChockId: null,
	RivCassetteChockName: null,
	AveCassetteChockId: null,
	AveCassetteChockName: null,
	IsActive: true
};
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { CatalogBarrel } from "../../models/CatalogBarrel";
import { getBarrels } from "../../repositories/CatalogBarrelRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TableBarrel } from "./components/TableBarrel";

export const useBarrels = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<CatalogBarrel[], Error>({
    queryKey: ["barrels", partId],
    queryFn: () => getBarrels(partId),
    onError: onError,
  });
};

export const CatalogBarrelPage = () => {
  const classes = usePageStyles();

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableBarrel />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Carrier } from "../../models/parts/Carrier";

export const getCarriers = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetCarriers]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Carrier[];
	}
	else {
		throw new Error(resp.message || "Error fetching carriers");
	}
};
export const upsertCarrier = async (carrier: Carrier) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: carrier.PartId },
		{ name: "PartName", value: carrier.PartName },
		{ name: "LocationId", value: carrier.LocationId },
		{ name: "AssemblyStatusCode", value: carrier.AssemblyStatusCode },
		{ name: "CassetteId", value: carrier.CassetteId },
		{ name: "UpperRollId", value: carrier.UpperRollId },
		{ name: "LowerRollId", value: carrier.LowerRollId },
		{ name: "RollsetId", value: carrier.RollsetId },
		{ name: "UMChockId", value: carrier.UMChockId },
		{ name: "LMChockId", value: carrier.LMChockId },
		{ name: "UOChockId", value: carrier.UOChockId },
		{ name: "LOChockId", value: carrier.LOChockId },
		{ name: "EntryGuideId", value: carrier.EntryGuideId },
		{ name: "ExitGuideId", value: carrier.ExitGuideId },
		{ name: "Bait", value: carrier.Bait },
		{ name: "WeighUp", value: carrier.WeighUp },
		{ name: "ScheduledSectionId", value: carrier.ScheduledSectionId },
		{ name: "ScheduledLocationId", value: carrier.ScheduledLocationId },
	];

	const resp = await apiService.callV2("[M2].[UpsertCarrier]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting carriers");
	}
};

export const deleteCarrier = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	const resp = await apiService.callV2("[M2].[DeleteCarrier]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting carrier");
	}
};
import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogBarrel } from "../models/CatalogBarrel";

export const getBarrels = async (barrelId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (barrelId)
		parameters.push({ name: "BarrelId", value: barrelId });

	const resp = await apiService.callV2("[M2].[GetBarrels]", parameters);
	
	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as CatalogBarrel[];
	}
	else {
		throw new Error(resp.message || "Error fetching barrels");
	}
};
export const upsertBarrel = async (barrel: CatalogBarrel) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrel.BarrelId },
		{ name: "BarrelName", value: barrel.BarrelName },
		{ name: "Comments", value: barrel.Comments },
	];

	const resp = await apiService.callV2("[M2].[UpsertBarrel]", parameters);

	if (resp.ok){
		return get(resp, "data.returnValue", []) as number;
	}
	else {
		throw new Error(resp.message || "Error upserting barrel");
	}
};

export const deleteBarrel = async (barrelId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelId }
	];

	const resp = await apiService.callV2("[M2].[DeleteBarrel]", parameters);

	if (!resp.ok) {
		throw new Error(resp.message || "Error upserting barrel");
	}
};
export interface CatalogSection {
	SectionId: number | null;
	SectionName: string;
	SectionFamilyId: number | null;
	SectionFamilyName: string;
	IsActive: boolean | null;
}

export const CATALOG_SECTION_INITIAL_VALUES: CatalogSection = {
	SectionId: null,
	SectionName: '',
	SectionFamilyId: null,
	SectionFamilyName: "",
	IsActive: true
};
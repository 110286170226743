export interface Donut {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null;
	DonutStatusCode: string;
	BarrelId: number | null;
	BarrelName: string;
	Diameter: number | null;
	NewDiameter: number | null;
	ScrapDiameter: number | null;
	IsActive: boolean | null;
}

export const DONUT_INITIAL_VALUES: Donut = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	DonutStatusCode: 'R',
	BarrelId: null,
	BarrelName: '',
	Diameter: null,
	NewDiameter: null,
	ScrapDiameter: null,
	IsActive: true
};
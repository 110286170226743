import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { CassetteChock } from "../../../../models/parts/CassetteChock";

type Props = {
  setCassetteChockId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setCassetteChockId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "RFIDTag",
      headerName: "Tag",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      field: "LocationName",
      headerName: "Location",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setCassetteChockId,
        id: (params.data as CassetteChock).PartId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "cassetteChockContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { columnDefs };
};

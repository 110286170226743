import { MenuItem, MenuList } from "@material-ui/core";
import { ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import CatalogBarrel from "../pages/configuration/pages/barrels";
import CatalogLocation from "../pages/configuration/pages/locations";
import Arbor from "../pages/configuration/pages/parts/arbors";
import Carrier from "../pages/configuration/pages/parts/carriers";
import CassetteChock from "../pages/configuration/pages/parts/cassette-chocks";
import Cassette from "../pages/configuration/pages/parts/cassettes";
import Chock from "../pages/configuration/pages/parts/chocks";
import Donut from "../pages/configuration/pages/parts/donuts";
import EdgerRoll from "../pages/configuration/pages/parts/edger-rolls";
import Guide from "../pages/configuration/pages/parts/guides";
import UniversalRoll from "../pages/configuration/pages/parts/universal-roll";
import CatalogPassShape from "../pages/configuration/pages/pass-shapes";
import CatalogSectionFamily from "../pages/configuration/pages/section-families";
import CatalogSection from "../pages/configuration/pages/sections";
import CatalogCategories from "../pages/delays/categories";
import CatalogDelayCodes from "../pages/delays/codes";

type Component = {
  path: string;
  sidebar: () => ReactNode;
  main: () => ReactNode;
};

const components: Component[] = [
  {
    path: "delay-categories",
    sidebar: () => <div>Categories</div>,
    main: () => <CatalogCategories />,
  },
  {
    path: "delay-codes",
    sidebar: () => <div>Codes</div>,
    main: () => <CatalogDelayCodes delayAreaAssetName="Mill #2" />,
  },
  {
    path: "arbors",
    sidebar: () => <div>Arbors</div>,
    main: () => <Arbor />,
  },
  {
    path: "barrels",
    sidebar: () => <div>Barrels</div>,
    main: () => <CatalogBarrel />,
  },
  {
    path: "carriers",
    sidebar: () => <div>Carriers</div>,
    main: () => <Carrier />,
  },
  {
    path: "cassettes",
    sidebar: () => <div>Cassettes</div>,
    main: () => <Cassette />,
  },
  {
    path: "cassette-chocks",
    sidebar: () => <div>Cassette Chocks</div>,
    main: () => <CassetteChock />,
  },
  {
    path: "chocks",
    sidebar: () => <div>Chocks</div>,
    main: () => <Chock />,
  },
  {
    path: "donuts",
    sidebar: () => <div>Donuts</div>,
    main: () => <Donut />,
  },
  {
    path: "edger-rolls",
    sidebar: () => <div>Edger Rolls</div>,
    main: () => <EdgerRoll />,
  },
  {
    path: "guides",
    sidebar: () => <div>Guides</div>,
    main: () => <Guide />,
  },
  {
    path: "locations",
    sidebar: () => <div>Locations</div>,
    main: () => <CatalogLocation />,
  },
  {
    path: "pass-shapes",
    sidebar: () => <div>Pass Shapes</div>,
    main: () => <CatalogPassShape />,
  },
  {
    path: "universal-rolls",
    sidebar: () => <div>Universal Roll</div>,
    main: () => <UniversalRoll />,
  },
  {
    path: "sections",
    sidebar: () => <div>Sections</div>,
    main: () => <CatalogSection />,
  },
  {
    path: "section-families",
    sidebar: () => <div>Sections Families</div>,
    main: () => <CatalogSectionFamily />,
  },
];

const sidebar = () => {
  const { option } = useParams();

  return (
    <>
      <h3 className="mes-menu-group">Rolling Mill</h3>
      <MenuList className="mes-submenu">
        <MenuItem selected={option === "section-families"} className="p-0">
          <Link to="/configuration/section-families" className="nav-link">
            Section Families
          </Link>
        </MenuItem>
        <MenuItem selected={option === "sections"} className="p-0">
          <Link to="/configuration/sections" className="nav-link">
            Sections
          </Link>
        </MenuItem>
        <MenuItem selected={option === "arbors"} className="p-0">
          <Link to="/configuration/arbors" className="nav-link">
            Arbors
          </Link>
        </MenuItem>
        <MenuItem selected={option === "donuts"} className="p-0">
          <Link to="/configuration/donuts" className="nav-link">
            Donuts
          </Link>
        </MenuItem>
        <MenuItem selected={option === "universal-rolls"} className="p-0">
          <Link to="/configuration/universal-rolls" className="nav-link">
            Universal Rolls
          </Link>
        </MenuItem>
        <MenuItem selected={option === "cassettes"} className="p-0">
          <Link to="/configuration/cassettes" className="nav-link">
            Cassettes
          </Link>
        </MenuItem>
        <MenuItem selected={option === "chocks"} className="p-0">
          <Link to="/configuration/chocks" className="nav-link">
            Chocks
          </Link>
        </MenuItem>
        <MenuItem selected={option === "cassette-chocks"} className="p-0">
          <Link to="/configuration/cassette-chocks" className="nav-link">
            Cassette Chocks
          </Link>
        </MenuItem>
        <MenuItem selected={option === "pass-shapes"} className="p-0">
          <Link to="/configuration/pass-shapes" className="nav-link">
            Pass Shapes
          </Link>
        </MenuItem>
        <MenuItem selected={option === "barrels"} className="p-0">
          <Link to="/configuration/barrels" className="nav-link">
            Barrels
          </Link>
        </MenuItem>
        <MenuItem selected={option === "edger-rolls"} className="p-0">
          <Link to="/configuration/edger-rolls" className="nav-link">
            Edger Rolls
          </Link>
        </MenuItem>
        <MenuItem selected={option === "guides"} className="p-0">
          <Link to="/configuration/guides" className="nav-link">
            Guides
          </Link>
        </MenuItem>
        <MenuItem selected={option === "carriers"} className="p-0">
          <Link to="/configuration/carriers" className="nav-link">
            Carriers
          </Link>
        </MenuItem>
        <MenuItem selected={option === "locations"} className="p-0">
          <Link to="/configuration/locations" className="nav-link">
            Locations
          </Link>
        </MenuItem>
      </MenuList>
      <h3 className="mes-menu-group">Delays</h3>
      <MenuList className="mes-submenu">
        <MenuItem selected={option === "categories"} className="p-0">
          <Link to="/configuration/delay-categories" className="nav-link">
            Categories
          </Link>
        </MenuItem>
        <MenuItem selected={option === "codes"} className="p-0">
          <Link to="/configuration/delay-codes" className="nav-link">
            Codes
          </Link>
        </MenuItem>
      </MenuList>
    </>
  );
};

const customConfigurations: any = [components, sidebar];

export { customConfigurations };

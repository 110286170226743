import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Section } from "../models/Section";
import { getSections } from "../repositories/SectionRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

const useSections = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Section[], Error>({
    queryKey: ["sections", partId],
    queryFn: () => getSections(partId),
    onError: onError,
  });
};

export const SectionSelector = ({
  label,
  value,
  onChange,
  disabled,
}: Props) => {
  const [error, setError] = useState("");

  const { data: sections, isLoading } = useSections(undefined, (error) =>
    setError(error.message)
  );

  const selectedValue =
    sections?.find((section) => section.SectionId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={sections || []}
        getOptionLabel={(option) => option.SectionName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.SectionId ?? null);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading sections"
      />
    </>
  );
};

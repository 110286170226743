import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useQuery } from "@tanstack/react-query";
import { CatalogPassShape } from "../../models/CatalogPassShape";
import { getPassShapes } from "../../repositories/CatalogPassShapeRepository";
import { usePageStyles } from "../../styles/pageStyles";
import { TablePassShape } from "./components/TablePassShape";

type usePassShapesParams = {
  passShapeId?: number;
  typeCode?: string;
  onError?: (error: Error) => void;
};

export const usePassShapes = ({
  passShapeId,
  typeCode,
  onError,
}: usePassShapesParams) => {
  return useQuery<CatalogPassShape[], Error>({
    queryKey: ["passShapes", passShapeId, typeCode],
    queryFn: () => getPassShapes(passShapeId, typeCode),
    onError: onError,
  });
};

export const CatalogPassShapePage = () => {
  const classes = usePageStyles();
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TablePassShape />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FieldError } from "react-hook-form";
import { Roll } from "../models/Roll";
import { getRolls } from "../repositories/RollRepository";

type Props = {
  label: string;
  value: number | null;
  onChange?: Function;
  fieldError?: FieldError | undefined;
  disabled: boolean;
};

type useRollsProps = {
  onError: (error: Error) => void;
  onSuccess?: (data: Roll[]) => void;
};

export const useRolls = ({ onError, onSuccess }: useRollsProps) => {
  return useQuery<Roll[], Error>({
    queryKey: ["rolls"],
    queryFn: () => getRolls(),
    onError: onError,
    onSuccess: onSuccess,
  });
};

export const RollSelector = ({
  label,
  value,
  onChange = () => {},
  fieldError,
  disabled,
}: Props) => {
  const [error, setError] = useState("");

  const { data: rolls, isLoading } = useRolls({
    onError: (error) => setError(error.message),
  });

  const selectedValue = rolls?.find((roll) => roll.PartId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={rolls || []}
        getOptionLabel={(option) => option.PartName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            error={!!fieldError}
            helperText={fieldError?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rolls"
      />
    </>
  );
};

export interface CatalogBarrel {
	BarrelId: number | null;
	BarrelName: string;
	PassShapeId: number | null;
	PassShapeAmmount: number | null;
	Comments: string;
	IsActive: boolean | null;
}

export const CATALOG_BARREL_INITIAL_VALUES: CatalogBarrel = {
	BarrelId: null,
	BarrelName: '',
	PassShapeId: null,
	PassShapeAmmount: null,
	Comments: '',
	IsActive: true
};
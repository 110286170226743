import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { BarrelPassShape } from "../models/SelectedPassShape";

export const getBarrelPassShapes = async (passShapeId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "BarrelId", value: passShapeId ?? 0 }]

	const resp = await apiService.callV2("[M2].[GetBarrelPassShapes]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as BarrelPassShape[];
	}
	else {
		throw new Error(resp.message || "Error fetching barrel pass shapes");
	}
};

export const deleteBarrelPassShape = async (barrelPassShape: BarrelPassShape) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelPassShape.BarrelId },
		{ name: "Position", value: barrelPassShape.Position }
	];

	const resp = await apiService.callV2("[M2].[DeleteBarrelPassShape]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting barrel pass shape");
	}
};

export const setBarrelPassShapes = async (barrelId: number, passShapeId: number, amount: number) => {
	const apiService = new MESApiService();

	const passShapes = Array(amount).fill(passShapeId);

	const passShapesInfoString = passShapes.map((value, index) => 
		`${index + 1},${value}`
	).join(';');

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelId },
	];

	if (passShapesInfoString)
		parameters.push({ name: "PassShapeInfo", value: passShapesInfoString })

	return await apiService.callV2("[M2].[SetBarrelPassShapes]", parameters);
};


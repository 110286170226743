export interface CatalogPassShape {
	PassShapeId: number | null;
	PassShapeName: string;
	PassShapeTypeCode: string;
	ShapeId: number | null;
	ShapeName: string;
	IsActive: boolean | null;
}

export const CATALOG_PASS_SHAPE_INITIAL_VALUES: CatalogPassShape = {
	PassShapeId: null,
	PassShapeName: '',
	PassShapeTypeCode: '',
	ShapeId: null,
	ShapeName: '',
	IsActive: true
};
export interface Guide {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null,
	GuideTypeCode: string | null;
	IsActive: boolean | null;
}

export const GUIDE_INITIAL_VALUES: Guide = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	GuideTypeCode: null,
	IsActive: true
};
export interface Chock {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null;
	RebuildTimestamp: Date | null;
	BearingChangeTimestamp: Date | null;
	IsActive: boolean | null;
}

export const CHOCK_INITIAL_VALUES: Chock = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	RebuildTimestamp: null,
	BearingChangeTimestamp: null,
	IsActive: true
};